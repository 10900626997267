import React from "react";
import { strapiUrl } from "../../helpers/strapiUrl";
import { Container } from "../../helpers/container";
import LightGallery from "lightgallery/react";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import {useWidth} from "../../hooks/useWidth";

const GalleryFragment = ({ title, images, revert }: any) => {
  const {isDesktop} = useWidth()
  return (
    <>
      <h1
        className="galleryComponent__header"
        style={{
          alignSelf: !revert ? `flex-end` : `flex-start`,
          textAlign: !revert ? `left` : `right`,
            marginBottom:isDesktop ? '22px' :"0",
        }}
      >
        {title}
      </h1>
      <Container className="galleryComponent__images">
        <div id={title} className="galleryComponent__images">
          <LightGallery
            onInit={() => {}}
            speed={500}
            plugins={[lgThumbnail, lgZoom]}
          >
            {images.map(({ url }: any) => {
              return (
                <a href={strapiUrl(url)}>
                  <img
                    className="galleryComponent__images--element"
                    src={strapiUrl(url)}
                  />
                </a>
              );
            })}
          </LightGallery>
        </div>
      </Container>
    </>
  );
};

export const GalleryComponent = ({ gallerySection }: any) => {
  console.log(gallerySection);
  return (
    <div className="galleryComponent">
      {gallerySection.map((gallerySectionProps: any, index: number) => {
        return <GalleryFragment revert={index % 2} {...gallerySectionProps} />;
      })}
    </div>
  );
};
