import React, { useEffect, useState } from "react";
import { Layout } from "../components/utilityComponents/layout";
import { MainPageHeader as AboutUsPageHeader } from "../components/mainPageComponents/mainPageHeader";
import { graphql, StaticQuery } from "gatsby";
import { ImageWithText } from "../components/utilityComponents/imageWithText";
import { Button } from "../components/utilityComponents/button";
import { useWidth } from "../hooks/useWidth";
import { GalleryHeader } from "../components/galleryPageComponents/galleryHeader";
import { strapiUrl } from "../helpers/strapiUrl";
import { GalleryComponent } from "../components/galleryPageComponents/galleryComponent";

export default function GaleriaPage() {
  const { width, isDesktop } = useWidth();

  return (
    <StaticQuery
      query={graphql`
        {
          strapiGallery {
            id
            heroVideo {
              url
            }
            gallerySection {
              images {
                id
                url
              }
              title
            }
          }
        }
      `}
      render={({ strapiGallery: { heroVideo, gallerySection } }) => {
        return (
          <Layout
            href="#2"
            darkBackground
            halfHeightBackground
            headerChildren={
              <GalleryHeader
                videoUrl={heroVideo?.url ? strapiUrl(heroVideo.url) : ""}
                gallerySection={gallerySection}
              />
            }
          >
            <GalleryComponent gallerySection={gallerySection} />
            <div className="onas__buttonContainer">
              <Button
                style={{ margin: isDesktop ? "4rem 1rem" : "5px 21px 32px" }}
                label="Zarezerwuj stolik"
                size="large"
                type="secondary"
                onClick={()=>window.location="/rezerwacja"}
              />
              <Button
                  onClick={() => window.location.href = 'https://koneser.s4honline.pl/'}
                style={{ margin: isDesktop ? "4rem 1rem" : "32px 21px 5px" }}
                label="Zamów online"
                size="large"
                type="primary"
              />
            </div>
          </Layout>
        );
      }}
    />
  );
}
