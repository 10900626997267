import React from "react";
import { Button } from "../utilityComponents/button";
// @ts-ignore
import { Player } from "video-react";
import "video-react/dist/video-react.css"; // import css
import GalleryFB from "./../../assets/images/galleryFB.png";
import GalleryInsta from "./../../assets/images/galleryInsta.png";
import { useWidth } from "../../hooks/useWidth";
export const GalleryHeader = ({ videoUrl, gallerySection }: any) => {
  const { isDesktop } = useWidth();
  return (
    <div className="galleryHeader">
      <h1 className="galleryHeader__header">Przestrzeń Pozytywnych Emocji</h1>
      <div className="galleryHeader__buttons">
        {gallerySection.map(({ title }: any) => {
          return (
            <Button
              onClick={() => (window.location.href = "#" + title)}
              style={{ margin: isDesktop ? "16px 0" : "0 0 16px 0" }}
              label={title}
              size="medium"
              type="secondary"
            />
          );
        })}
      </div>
      <div className="galleryHeader__videos">
        <div className="galleryHeader__wrapper">
          <Player playsInline src={videoUrl} />
        </div>
        <img />
        <div className="galleryHeader__icons">
          <img src={GalleryFB}   onClick={() =>
              window.open(
                  "https://www.facebook.com/Koneser-Resto-Club-109379881696227",
                  "_blank"
              )
          }/>
          <img src={GalleryInsta}  onClick={() =>
              window.open(
                  "https://www.instagram.com/koneser_restauracja",
                  "_blank"
              )
          } />
        </div>
      </div>
    </div>
  );
};
